@page {
  margin: 0;
}

@media print {
  #main {
    /* overflow: visible !important; */
  }
  @page {
    /* margin: 0; */
    /* padding: 0; */
    size: auto;
    margin-top: 1rem; /* Adjust the value as needed */
    page-title: "";
  }
  body {
    size: A4;
  }
  .main {
    page-break-before: always;
  }
  #invoice-container {
    width: 100%;
    height: 100%;
    page-break-before: always;
    /* ... other styles ... */
  }
  @page {
    size: 100% 100%;
  }
  header h2 {
    /* display: hidden; */
    font-size: 2rem !important;
  }
  .page-content {
    margin: 0;
    max-width: 90vw !important;
    /* min-height: 100vh !important; */
  }
  .page-content .container {
    max-width: 90vw !important;
  }
  .page-header .h1 {
    /* display: none; */
  }
  #print {
    display: none;
  }
  #printButton {
    display: none;
  }
  #backButton {
    display: none;
  }
  .page-content {
    /* min-height: 29.7cm; */
    position: relative;
  }
  #footer_greet {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

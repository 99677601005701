.loginScreen {
  background-image: url("./assets/images/pattern/loginScreen.png");
  background-size: contain;
  /* background-repeat: no-repeat */
}
.stickyTab {
  top: 64px;
}
.page-scrolled .stickyTab {
  top: 0;
}
.stickySearch {
  top: 112px;
}
.page-scrolled .stickySearch {
  top: 48px;
}

.page-top .tabFilter-shadow {
  box-shadow: none !important;
}

.hide-scroll {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Edge and IE */
}

.hide-scroll::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.style-scroll::-webkit-scrollbar{
  width: 5px;
}
.style-scroll::-webkit-scrollbar-track{
  background: #f1f1f1;
  border-radius: 25px;
}
.style-scroll::-webkit-scrollbar-thumb{
  background: #ccc;
  border-radius: 25px;
}
.style-scroll::-webkit-scrollbar-thumb:hover{
  background: #b3b3b3;
}


.bottomTabBar{
    bottom: 0rem;
}
.start-scrolling .bottomTabBar{
    bottom: -4rem;
}

.floatedCart{
  bottom: 4.5rem;
}
.start-scrolling .floatedCart{
    bottom: 0.5rem;
}

.qrScanBlock{
  background-image: url('./assets//images/pattern/animation_lnfydonn_small.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
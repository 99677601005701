

.text-secondary-d1 {
    color: #728299!important;
}
.page-header {
    margin: 0 0 0;
    padding-top: 1rem;
    border-bottom: 1px dotted #e2e2e2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}
.page-title {
    padding: 0;
    margin: 0;
    font-size: 1.75rem;
    font-weight: 300;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.text-grey-m2 {
    color: #888a8d!important;
}

.text-success-m2 {
    color: #86bd68!important;
}

.font-bolder, .text-600 {
    font-weight: 600!important;
}

.text-110 {
    font-size: 110%!important;
}
.text-blue {
    color: #9a5d04!important;
}
.pb-25, .py-25 {
    padding-bottom: .75rem!important;
}

.pt-25, .py-25 {
    padding-top: .75rem!important;
}
.bgc-default-tp1 {
    background-color: #032309  !important;
}
.bgc-default-tp1 tr th{
    color: #f8faf9;
}
.bgc-default-l4, .bgc-h-default-l4:hover {
    background-color: #f3f8fa!important;
}
.page-header .page-tools {
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.btn-light {
    color: #757984;
    background-color: #f5f6f9;
    border-color: #dddfe4;
}
.w-2 {
    width: 1rem;
}

.text-120 {
    font-size: 120%!important;
}
.text-primary-m1 {
    color: #0e4719!important;
}

.text-danger-m1 {
    color: #dd4949!important;
}
.text-blue-m2 {
    color: #869563!important;
}
.text-150 {
    font-size: 150%!important;
}
.text-60 {
    font-size: 60%!important;
}
.text-grey-m1 {
    color: #7b7d81!important;
}
.align-bottom {
    vertical-align: bottom!important;
}

.table thead tr{
    border-radius: 8px;
}
.table thead tr th:first-child{
    border-top-left-radius: 8px;
    }
.table thead tr th:last-child{
    border-top-right-radius: 8px;
}

.table tbody tr:nth-child(odd){
    background-color: rgba(218, 245, 225,0.5) !important;
}
.table tbody tr:nth-child(even){
    background-color: rgba(181, 201, 186,0.5) !important;
}

.page-content{
    min-height: 29cm;
    position:relative;
}
#footer_greet{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed&family=Nunito&family=Overpass&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Andika:wght@400;700&family=Dancing+Script:wght@600;700&family=Sansita:wght@400;700&display=swap");
@layer base {
  :root {
    --color-nav-text: #f8fafc;
  }
}

@layer utilities {
  .btn-rounded {
    @apply select-none text-primary-500 dark:text-primary-200 transition-all duration-300 outline-none shadow-sm shadow-primary-100 dark:shadow-primary-400 rounded-full text-center px-2 py-2;
  }

  .tab-active {
    @apply before:content-[''] before:absolute before:bottom-0 before:left-0 before:h-1 before:w-full  before:rounded-t-md;
  }

  .tabFilter-shadow {
    @apply shadow-1;
  }
}

.primary-font {
  font-family: "Dancing Script", cursive;
}
.secondary-font {
  font-family: "Sansita", sans-serif;
}
.third-font {
  font-family: "Andika", sans-serif;
}
.light-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.shadow-1 {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: rgba(128, 128, 128, 0.3) 0px 1px 1px,
    rgba(128, 128, 128, 0.15) 0px 2px 1px;
}

.shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.16) 1px 2px 3px, rgba(0, 0, 0, 0.08) 1px 2px 3px,
    rgba(0, 0, 0, 0.16) -1px -2px 3px, rgba(0, 0, 0, 0.08) -1px -2px 3px;
}

.shadow-3 {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1),0 -1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-4 {
  /* box-shadow: rgba(128, 128, 128, 0.3) 1px 1px 1px,
    rgba(128, 128, 128, 0.15) 1px 2px 1px,
    rgba(128, 128, 128, 0.3) -1px -1px 1px,
    rgba(128, 128, 128, 0.15) -1px -2px 1px; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.disable-scroll {
  overflow: hidden;
}